import Helmet from 'components/HeadTags/Helmet';
import { useAuth } from 'hooks/auth/useAuth';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import DwellsyModalLogo from 'public/images/dwellsymodallogo.png';
import React, { Suspense, useEffect } from 'react';
import HeaderFooterTemplate from 'templates/HeaderFooterTemplate';
import { LOCATIONS } from 'utils/constants/locations';
import { EVENTS } from 'utils/tracking/events';
import { logEvent } from 'utils/tracking/gtm';

const LoginForm = dynamic(() => import('components/form/LoginForm/LoginForm'), {
  suspense: true,
});

export default function LoginPage() {
  const auth = useAuth();
  useEffect(() => {
    if (auth?.loggedIn) window.location.href = '/';
  }, [auth?.loggedIn]);

  useEffect(() => {
    logEvent(EVENTS.SIGN_UP_INITIATE, {
      location: LOCATIONS.LOGIN_PAGE,
    });
  }, []);

  return (
    <HeaderFooterTemplate>
      <Helmet
        title="Log in to Dwellsy | Built for renters"
        description="Log in to Dwellsy, the best place to find a proeprty and make it home."
      />
      <div className="text-center flex flex-col justify-center space-y-3 items-center px-4 w-full">
        <Image
          src={DwellsyModalLogo}
          alt="Dwellsy Logo"
          width={50}
          height={50}
          layout="fixed"
        />
        <Suspense fallback={<div />}>
          <LoginForm />
        </Suspense>
      </div>
    </HeaderFooterTemplate>
  );
}
